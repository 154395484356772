/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBContainer,
  MDBIcon,
  MDBTooltip,
} from 'mdbreact';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuth } from '../../hooks/auth';

import './styles.css';

import logoFirst from '../../assets/logo-first.png';

const Header: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { user, signOut } = useAuth();
  const cartSize = useSelector((state: any) => state.cart.length);

  const handleTogglerClick = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  const overlay = (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div id="sidenav-overlay" onClick={handleTogglerClick} />
  );

  const handleLogOff = React.useCallback(async () => {
    try {
      await signOut();
      toast.success('Você saiu da loja com sucesso!');
    } catch {
      toast.error('Não foi possível sair da loja. Tente novamente.');
    }
  }, [signOut]);

  return (
    <>
      <MDBNavbar
        color="primary-color"
        dark
        expand="md"
        fixed="top"
        scrolling
        transparent
        className="nav-bar"
      >
        <MDBContainer>
          <MDBNavbarToggler className="nav-icon" onClick={handleTogglerClick} />
          <MDBCollapse isOpen={collapsed} navbar>
            <MDBNavbarNav left>
              <img className="logo" src={logoFirst} alt="logo" />
              <MDBNavItem active>
                <MDBNavLink className="nav-link" to="/">
                  Home
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink className="nav-link" to="/products">
                  Produtos
                </MDBNavLink>
              </MDBNavItem>
            </MDBNavbarNav>
          </MDBCollapse>
          <MDBNavbarNav right>
            {/* <MDBNavItem>
              <MDBFormInline waves>
                <input
                  className="input-search"
                  type="text"
                  placeholder="Digite o que você procura"
                  aria-label="Search"
                />
              </MDBFormInline>
            </MDBNavItem> */}
            <MDBNavItem className="cart">
              <Link to="/cart">
                <div className="icons-cart">
                  {user && cartSize && cartSize > 0 && (
                    <MDBTooltip placement="top">
                      <MDBIcon icon="circle" className="color-icon" />
                      <div>Você tem produtos no seu carrinho.</div>
                    </MDBTooltip>
                  )}
                  <MDBIcon icon="shopping-cart" size="2x" />
                </div>
              </Link>
            </MDBNavItem>
            {user && (
              <MDBNavItem>
                <p style={{ marginLeft: 15, marginTop: -2 }}>
                  Olá, {user.email}
                </p>
                <button
                  type="button"
                  onClick={handleLogOff}
                  style={{ border: 'none', background: 'none' }}
                >
                  <span
                    style={{
                      marginLeft: 45,
                      fontWeight: 'bold',
                      cursor: 'pointer',
                    }}
                  >
                    SAIR
                  </span>
                </button>
              </MDBNavItem>
            )}
          </MDBNavbarNav>
        </MDBContainer>
      </MDBNavbar>
      {collapsed && overlay}
    </>
  );
};

export default Header;
