import { createStore, applyMiddleware } from 'redux';

import createSagaMiddleware from 'redux-saga';
import rootSaga from './modules/rootSaga';

import rootReducer from './modules/rootReducer';

const sagaMonitor = undefined;
const sagaMiddleware = createSagaMiddleware({
  sagaMonitor,
});

const enhancer = applyMiddleware(sagaMiddleware);

const store = createStore(rootReducer, enhancer);

sagaMiddleware.run(rootSaga);

export default store;
