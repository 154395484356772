import React from 'react';

import { AuthProvider } from './auth';
import { CartProvider } from './cart';

const AppProvider: React.FC = ({ children }) => (
  <CartProvider>
    <AuthProvider>{children}</AuthProvider>
  </CartProvider>
);
export default AppProvider;
