import React from 'react';

import * as RS from './styles';

const Receipt: React.FC = () => {
  return (
    <RS.Container>
      <RS.Text>
        <strong>
          Tudo certo! Em breve entraremos em contato. Obrigado pela preferência!
        </strong>
      </RS.Text>
    </RS.Container>
  );
};

export default Receipt;
