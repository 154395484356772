/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  MdRemoveCircleOutline,
  MdAddCircleOutline,
  MdDelete,
} from 'react-icons/md';
import {
  Fade,
  Modal,
  Backdrop,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { FiDelete } from 'react-icons/fi';
import { formatPrice } from '../../util/format';
import { ProductProps } from '../../@types';
import { Container, ProductTable, Total } from './styles';
import * as CartActions from '../../store/modules/cart/actions';
import { useAuth } from '../../hooks/auth';
import { useCart } from '../../hooks/cart';

interface IItems {
  product_id: string;
  quantity: number;
  sub_total: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20,
      },
    },
  }),
);

const Cart: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { user } = useAuth();
  const { submitOrder } = useCart();
  const { push } = useHistory();

  React.useEffect(() => {
    if (!user) {
      push('/');
    }
  }, [user, push]);

  const classes = useStyles();

  const handleClose = useCallback(() => {
    setOpen(!open);
  }, [setOpen, open]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const total = useSelector((state: any) =>
    formatPrice(
      state.cart.reduce((totalPrice: number, product: ProductProps) => {
        return totalPrice + product.price * product.amount;
      }, 0),
    ),
  );

  const totalNumber = useSelector((state: any) =>
    state.cart.reduce((totalPrice: number, product: ProductProps) => {
      return totalPrice + product.price * product.amount;
    }, 0),
  );

  const cart = useSelector((state: any) =>
    state.cart.map((product: ProductProps) => ({
      ...product,
      subTotal: formatPrice(product.price * product.amount),
    })),
  );

  const dispatch = useDispatch();

  const increment = useCallback(
    async (id: string, amount: number) => {
      dispatch(CartActions.updateAmountRequest(id, amount + 1));
    },
    [dispatch],
  );

  const decrement = useCallback(
    async (id: string, amount: number) => {
      dispatch(CartActions.updateAmountRequest(id, amount - 1));
    },
    [dispatch],
  );

  const handleSubmit = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      const items: IItems[] = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const item of cart) {
        items.push({
          product_id: item.id,
          quantity: item.amount,
          sub_total: item.amount * item.price,
        });
      }

      const payload = {
        user_id: user.id,
        items,
        total: totalNumber,
      };

      await submitOrder(payload);

      push('/receipt');

      dispatch(CartActions.resetCart());

      setLoading(false);
    } catch {
      setLoading(false);
      toast.error(
        'Não foi possível realizar a compra, por favor tente novamente!',
      );
    }
  }, [user, cart, submitOrder, totalNumber, push, dispatch]);

  return (
    <>
      <Container>
        <ProductTable>
          <thead>
            <tr>
              <th />
              <th>PRODUTO</th>
              <th>QTD</th>
              <th>SUBTOTAL</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {cart.length > 0 ? (
              cart.map((product: ProductProps) => (
                <tr key={product.id}>
                  <td>
                    <img src={product.image_url} alt={product.name} />
                  </td>
                  <td>
                    <strong>{product.name}</strong>
                    <span>{formatPrice(product.price)}</span>
                  </td>
                  <td>
                    <div>
                      <button type="button">
                        <MdRemoveCircleOutline
                          size={20}
                          color="#7159c1"
                          onClick={() => decrement(product.id, product.amount)}
                        />
                      </button>
                      <input type="number" readOnly value={product.amount} />
                      <button type="button">
                        <MdAddCircleOutline
                          size={20}
                          color="#7159c1"
                          onClick={() => increment(product.id, product.amount)}
                        />
                      </button>
                    </div>
                  </td>
                  <td>
                    <strong>{product.subTotal}</strong>
                  </td>
                  <td>
                    <button type="button">
                      <MdDelete
                        size={20}
                        color="#7159c1"
                        onClick={() =>
                          dispatch(CartActions.removeFromCart(product.id))
                        }
                      />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <span>Nenhum produto adicionado ao carrinho ainda.</span>
            )}
          </tbody>
        </ProductTable>
        {cart.length > 0 && (
          <footer>
            <button type="button" onClick={handleOpen}>
              Finalizar pedido
            </button>
            <Total>
              <span>TOTAL</span>
              <strong>{total}</strong>
            </Total>
          </footer>
        )}
      </Container>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          {!loading ? (
            <div className={classes.paper}>
              <h2 id="transition-modal-title">
                Finalize seu pedido!
                <FiDelete
                  onClick={handleClose}
                  color="error"
                  size={18}
                  style={{ marginLeft: 15, top: 15, cursor: 'pointer' }}
                />
              </h2>
              <strong>Detalhes do pedido: </strong>
              <p>Itens: </p>
              {cart &&
                cart.length > 0 &&
                cart.map((product: ProductProps) => (
                  <p key={product.id}>
                    {product.name} - {product.amount}x = {product.subTotal}
                  </p>
                ))}
              <strong>Total: {total}</strong> <br />
              <strong>
                IMPORTANTE: Ao finalizar nós entraremos em contato com voçê para
                combinarmos a entrega!
              </strong>
              <br />
              <br />
              <Button
                variant="contained"
                onClick={handleSubmit}
                color="secondary"
              >
                Finalizar
              </Button>
            </div>
          ) : (
            <CircularProgress />
          )}
        </Fade>
      </Modal>
    </>
  );
};

export default Cart;
