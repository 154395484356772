import React from 'react';
import {
  MDBMask,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBAnimation,
} from 'mdbreact';
import { InputLabel, InputAdornment, Input } from '@material-ui/core';
import { toast } from 'react-toastify';
import { FiUser, FiLock } from 'react-icons/fi';
import { useAuth } from '../../hooks/auth';
import './styles.css';

const Home: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const { signIn, user } = useAuth();

  const handleSubmit = React.useCallback(
    async (e: React.FormEvent) => {
      try {
        setLoading(true);
        e.preventDefault();

        if (!email || !password) {
          toast.error('Todos os campos são obrigatórios.');
        }

        await signIn({
          email,
          password,
        });

        toast.success('Login feito com sucesso');

        setLoading(false);
      } catch {
        toast.error('Não foi possível fazer login. Tente novamente mais tarde');
        setLoading(false);
      }
    },
    [email, password, signIn],
  );
  return (
    <>
      <div className="inital-background">
        <MDBMask className="d-flex justify-content-center align-items-center gradient">
          <MDBContainer>
            {!user && (
              <MDBRow>
                <MDBAnimation
                  type="fadeInLeft"
                  delay=".3s"
                  className="black-text text-center text-md-left col-md-6 mt-xl-5 mb-5"
                />

                <MDBCol md="6" xl="5" className="mb-4">
                  <MDBAnimation type="fadeInRight" delay=".3s">
                    <MDBCard id="classic-card card">
                      <MDBCardBody className="black-text">
                        <h3 className="text-center">MINHA CONTA</h3>
                        <hr className="hr-light" />
                        <form onSubmit={handleSubmit}>
                          <InputLabel htmlFor="input-with-icon-adornment">
                            Email
                          </InputLabel>
                          <Input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            id="input-with-icon-adornment"
                            startAdornment={
                              <InputAdornment position="start">
                                <FiUser />
                              </InputAdornment>
                            }
                          />
                          <InputLabel htmlFor="input-with-icon-adornment">
                            Senha
                          </InputLabel>
                          <Input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            id="input-with-icon-adornment"
                            startAdornment={
                              <InputAdornment position="start">
                                <FiLock />
                              </InputAdornment>
                            }
                          />
                          <div className="text-center mt-4 black-text">
                            <MDBBtn
                              type="submit"
                              disabled={loading}
                              color="red"
                            >
                              {loading ? 'CARREGANDO' : 'ENTRAR'}
                            </MDBBtn>
                            <p>
                              Não possui uma conta? Ligue agora: (33) 3511 -
                              1198 e peça a sua!
                            </p>
                            <hr className="hr-light" />
                            <div className="text-center d-flex justify-content-center black-label">
                              <a href="#!" className="p-2 m-2">
                                <MDBIcon
                                  fab
                                  icon="facebook"
                                  className="black-text"
                                />
                              </a>
                              <a href="#!" className="p-2 m-2">
                                <MDBIcon
                                  fab
                                  icon="instagram"
                                  className="black-text"
                                />
                              </a>
                            </div>
                          </div>
                        </form>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBAnimation>
                </MDBCol>
              </MDBRow>
            )}
          </MDBContainer>
        </MDBMask>
      </div>
    </>
  );
};

export default Home;
