import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';

interface SignInCredentials {
  email: string;
  password: string;
}

interface IUser {
  id: string;
  email: string;
  password: string;
}

interface AuthContextData {
  user: IUser;
  token: string;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  userToken: string;
}

interface AuthState {
  token: string;
  user: object;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [userToken, setUserToken] = useState('');
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@padatiajl:token');
    const user = localStorage.getItem('@padatiajl:User');

    if (user && token) {
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('sessions', {
      email,
      password,
    });

    const { token, user } = response.data;
    setUserToken(token);
    localStorage.setItem('@padatiajl:token', token);
    localStorage.setItem('@padatiajl:User', JSON.stringify(user));

    setData({ token, user });
  }, []);

  const signOut = useCallback(async () => {
    localStorage.removeItem('@padatiajl:token');
    localStorage.removeItem('@padatiajl:User');

    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: data.user as IUser,
        token: data.token,
        signIn,
        signOut,
        userToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('UseAuth must be used  within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
