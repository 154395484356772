import { createGlobalStyle } from 'styled-components';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: 0;
    }

    body {
        background: #FFF;
        color: #000;
        -webkit-font-smoothing: antialiased;
    }

    body, input , button {
        font-size: 16px;
    }

    h1,h2, h3, h3, h5, h6, strong {
        font-weight: 500;
    }

    button: {
        cursor: pointer;
    }
`;
