import {
  CartRequestProps,
  AddToCartSuccessProps,
  UpdateAmountRequestProps,
  ProductProps,
} from '../../../@types';

export function addToCartRequest(id: string): CartRequestProps {
  return {
    type: '@cart/ADD_REQUEST',
    id,
  };
}

export function addToCartSuccess(product: ProductProps): AddToCartSuccessProps {
  return {
    type: '@cart/ADD_SUCCESS',
    product,
  };
}

export function removeFromCart(id: string): CartRequestProps {
  return {
    type: '@cart/REMOVE',
    id,
  };
}

export function updateAmountRequest(
  id: string,
  amount: number,
): UpdateAmountRequestProps {
  return {
    type: '@cart/UPDATE_AMOUNT_REQUEST',
    id,
    amount,
  };
}

export function updateAmountSuccess(
  id: string,
  amount: number,
): UpdateAmountRequestProps {
  return {
    type: '@cart/UPDATE_AMOUNT_SUCCESS',
    id,
    amount,
  };
}

export function resetCartSuccess(): object {
  return {
    type: '@cart/RESET_CART_SUCCESS',
  };
}

export function resetCart(): object {
  return {
    type: '@cart/RESET_CART',
  };
}
