import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { formatPrice } from '../../util/format';
import { Container, ProductList, useCardStyles } from './styles';
import * as CartActions from '../../store/modules/cart/actions';

import api from '../../services/api';

import { ProductProps } from '../../@types';

const ProductsCard: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<ProductProps[]>([]);
  const dispatch = useDispatch();
  const classes = useCardStyles();
  const { user } = useAuth();
  const { push } = useHistory();

  const loadProducts = React.useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await api.get('/products');

      setProducts(response.data);

      setLoading(false);
    } catch {
      setLoading(false);
      toast.error('Algo deu errado. Tente novamente');
    }
  }, []);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  const handleAddToCart = useCallback(
    async (id: string) => {
      if (!user) {
        push('/');
        toast.info('Faça login para visualizar os preços dos produtos.');
        return;
      }
      try {
        dispatch(CartActions.addToCartRequest(id));
      } catch {
        toast.error('Ops! Algo deu errado, tente novamente.');
      }
    },
    [dispatch, user, push],
  );

  const getButtonName = React.useCallback((): string => {
    if (user) {
      return 'Adicionar ao carrinho';
    }
    return 'Ver o Preço';
  }, [user]);

  return (
    <Container>
      {loading ? (
        <div style={{ marginLeft: '50vw' }}>
          <CircularProgress />
        </div>
      ) : (
        <ProductList>
          {products.length > 0 ? (
            products.map((product) => (
              <Card key={product.id} className={classes.root}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={product.image_url}
                    title={product.name}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {product.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {user !== undefined && formatPrice(product.price)}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {product.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleAddToCart(product.id)}
                  >
                    {product.amount === 0 ? 'Fora de estoque' : getButtonName()}
                  </Button>
                </CardActions>
              </Card>
            ))
          ) : (
            <strong>Nenhum produto adicionado ainda.</strong>
          )}
        </ProductList>
      )}
    </Container>
  );
};

export default ProductsCard;
