import React from 'react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import GlobalStyles from './styles/global';
import Routes from './Routes/index.routes';
import Header from './components/Header';
import Footer from './components/Footer';
import AppProvider from './hooks';
import store from './store';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Router>
        <AppProvider>
          <Header />
          <Routes />
        </AppProvider>
        <Footer />
        <ToastContainer />
        <GlobalStyles />
      </Router>
    </Provider>
  );
};

export default App;
