import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const Container = styled.div`
  width: 100vw;
  margin-top: 120px;
`;

export const ProductList = styled.ul`
  margin-left: 30px;

  @media (min-width: 700px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 16px;
    bottom: -28px;
    margin-left: 30px;

    strong {
      margin-left: 50px;
      margin-top: 50px;
      font: 700 1.6rem Archivo;
    }
  }
`;

export const useCardStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});
