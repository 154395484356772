import React from 'react';
import ProductsCard from '../../components/ProductsCard';

const Products: React.FC = () => {
  return (
    <>
      <ProductsCard />
    </>
  );
};

export default Products;
