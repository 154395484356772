import { call, select, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import { formatPrice } from '../../../util/format';
import {
  addToCartSuccess,
  updateAmountSuccess,
  resetCartSuccess,
} from './actions';

function* addToCart({ id }) {
  try {
    const token = localStorage.getItem('@padatiajl:token');

    const productExists = yield select((state) =>
      state.cart.find((p) => p.id === id),
    );

    const stock = yield call(api.get, `/stock`, {
      headers: {
        Authorization: `Bearer ${token}`,
        product_id: id,
      },
    });

    const stockAmount = stock.data;
    const currentAmount = productExists ? productExists.amount : 0;

    const amount = currentAmount + 1;

    if (amount > stockAmount) {
      toast.error('Quantidade solicitada fora do estoque');
      return;
    }

    if (productExists) {
      yield put(updateAmountSuccess(id, amount));
    } else {
      const response = yield call(api.get, `/product`, {
        headers: {
          Authorization: `Bearer ${token}`,
          id,
        },
      });

      const data = {
        ...response.data,
        amount: 1,
        priceFormated: formatPrice(response.data.price),
      };

      yield put(addToCartSuccess(data));

      toast.success('Produto adicionado no carrinho.');
    }
  } catch {
    toast.error('Ops! Algo de errado aconteceu, tente novamente');
  }
}

function* updateAmount({ id, amount }) {
  const token = localStorage.getItem('@padatiajl:token');

  if (amount <= 0) return;

  const stock = yield call(api.get, `/stock`, {
    headers: {
      Authorization: `Bearer ${token}`,
      product_id: id,
    },
  });

  const stockAmount = stock.data;

  if (amount > stockAmount) {
    toast.error('Quantidade solicitada fora do estoque');
    return;
  }
  yield put(updateAmountSuccess(id, amount));
}

function* resetCart() {
  yield select((state) => {
    state.cart = [];
  });

  yield put(resetCartSuccess());
}

export default all([
  takeLatest('@cart/ADD_REQUEST', addToCart),
  takeLatest('@cart/UPDATE_AMOUNT_REQUEST', updateAmount),
  takeLatest('@cart/RESET_CART', resetCart),
]);
