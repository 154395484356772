import React, { createContext, useCallback, useContext } from 'react';
import { toast } from 'react-toastify';
import api from '../services/api';

import { ProductProps } from '../@types';

interface CartContextData {
  addToCart(id: string): Promise<void>;
  updateAmount(id: string, amount: number): Promise<void>;
  removeFromCart(id: string): void;
  productCart: ProductProps[];
  submitOrder(data: any): Promise<void>;
}

const CartContext = createContext<CartContextData>({} as CartContextData);

const CartProvider: React.FC = ({ children }) => {
  const productCart: ProductProps[] = [];

  const addToCart = useCallback(
    async (id: string): Promise<void> => {
      try {
        const productExists = productCart.find((x) => x.id === id);
        const stock = await api.get(`/stock/${id}`);

        const stockAmount = stock?.data;
        const currentAmount = productExists ? productExists.amount : 0;

        const amount = currentAmount + 1;

        if (amount > stockAmount) {
          toast.error('Quantidade solicitada fora do estoque');
        }

        if (productExists) {
          productExists.amount += amount;
        } else {
          const response = await api.get(`/products/${id}`);

          if (response.data) {
            const { data } = response;
            data.amount = amount;
            productCart.push(data);
          }
        }
      } catch {
        toast.error('Algo deu errado. Tente novamente.');
      }
    },
    [productCart],
  );

  const updateAmount = useCallback(
    async (id: string, amount: number) => {
      try {
        if (amount <= 0) return;

        const stock = await api.get(`/stock/${id}`);
        const stockAmount = stock?.data;

        if (amount > stockAmount) {
          toast.error('Quantidade solicitada fora do estoque');
          return;
        }

        const productExists = productCart.find((x) => x.id === id);

        if (productExists) {
          productExists.amount = amount;
        }
      } catch {
        toast.error('Algo deu errado. Tente novamente.');
      }
    },
    [productCart],
  );

  const removeFromCart = useCallback(
    (id: string) => {
      const productIndex = productCart.findIndex((x) => x.id === id);

      if (productIndex !== -1) {
        productCart.splice(productIndex, 1);
      }
    },
    [productCart],
  );

  const submitOrder = useCallback(async (data): Promise<void> => {
    const token = localStorage.getItem('@padatiajl:token');
    await api.post('orders', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }, []);

  return (
    <CartContext.Provider
      value={{
        addToCart,
        removeFromCart,
        updateAmount,
        productCart,
        submitOrder,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

function useCart(): CartContextData {
  const context = useContext(CartContext);

  if (!context) {
    throw new Error('UseAuth must be used  within an AuthProvider');
  }

  return context;
}

export { CartProvider, useCart };
