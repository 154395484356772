import React from 'react';
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from 'mdbreact';
import './styles.css';
import locationIcon from '../../assets/icons/location-icon.png';
import phoneIcon from '../../assets/icons/phone-icon.png';
import mailIcon from '../../assets/icons/mail-icon.png';

const Footer: React.FC = () => {
  return (
    <MDBFooter className="font-small pt-4 mt-4 color-name">
      <MDBContainer fluid className="text-center text-md-left">
        <MDBRow>
          <MDBCol md="6">
            <h5 className="title">Dados da Loja</h5>
            <div className="left-container">
              <img src={locationIcon} alt="location" />
              <p>
                JL Embalagens e Padaria, Av. Frei Arcangelo n11- Itambacuri/MG
                Brasil 5039830-000
              </p>
            </div>
            <div className="left-container">
              <img src={phoneIcon} alt="phone" />
              <p>Ligue agora: (33) 3511 - 1198</p>
            </div>
            <div className="left-container">
              <img src={mailIcon} alt="phone" />
              <p>jlembalagensepadaria@hotmail.com</p>
            </div>
          </MDBCol>
          <MDBCol md="6">
            <h5 className="title">Sobre Nós</h5>
            <p>
              JL Embalagens e Padarias foi fundada em 23 de Novembro de 1995,
              inicialmente no ramo de embalagens logo em seguida Panificação.
              Hoje com sede própria na Frei Arcangelo nº 1150 – centro –
              Itambacuri/MG com preocupação em bem servir em preferencia em
              nossa área atual.
            </p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className="footer-copyright text-center py-3 copy">
        <MDBContainer className="infos" fluid>
          &copy; {new Date().getFullYear()} Copyright:{' '}
          <a href="https://thqueiroz-develop.netlify.app/"> THQ Develop </a>
        </MDBContainer>
      </div>
    </MDBFooter>
  );
};

export default Footer;
